import img1 from './img/figure/figure32.png'
import img2 from './img/figure/figure97.png'
import img3 from './img/slider/slider2.png'
import img4 from './img/my-img/bn.png'
import img5 from './img/my-img/bn.png'
import img6 from './img/slider/slider2.png'
import img7 from './img/slider/slider1.png'
import img8 from './img/figure/figure29.png'
import img9 from './img/figure/figure39.png'
import img10 from './img/figure/figure41.png'
import img11 from './img/figure/figure42.png'
import img12 from './img/figure/figure43.png'
import img13 from './img/figure/figure32.png'
import img14 from './img/my-img/figure33.png'
import img15 from './img/figure/figure30.png'
import img16 from './img/figure/figure31.png'
import img17 from './img/figure/figure18.png'
import img18 from './img/figure/figure46.png'
import img19 from './img/figure/figure21.png'
import img20 from './img/figure/figure47.png'
import img21 from './img/figure/figure20.png'
import img22 from './img/figure/figure49.png'
import img23 from './img/figure/figure13.png'
import img24 from './img/blog/blog4.jpg'
import img25 from './img/figure/figure11.png'
import img26 from './img/blog/blog3.jpg'
import img27 from './img/blog/blog37.jpg'
import img28 from './img/blog/blog38.jpg'
import img29 from './img/figure/figure5.png'
import img30 from './img/figure/figure6.png'
import img31 from './img/figure/figure21.png'

import footer1 from './img/figure/figure1.png'
import footer2 from './img/figure/figure2.png'
import footer3 from './img/my-img/lgo-red.png'
import footer4 from './img/figure/figure4.png'

export const homeImages = {
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
}

export const footerImages = {
    footer1,
    footer2,
    footer3,
    footer4
}